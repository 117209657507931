//rafc
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { SettingsProvider } from './context/SettingsContext';

import Home from './components/Home';
import Settings from './components/Settings';
import Site from './components/Site';
import Add from './components/Add';
import ContactMethods from './components/ContactMethods';
import NotFound from './components/NotFound';
import ContactMethod from './components/ContactMethod';
import {API_URL, LOGIN_URL} from './components/parts/Constants';
import Support from './components/Support';
import Sidebar from './components/parts/Sidebar';
import { Nav } from './components/parts/Nav';

function App() {
  const [authStatus, setAuthStatus] = useState(null);
  const [settings, setSettings] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false)
  const [sidebarReload, setSidebarRelaod] = useState(false)

  /**
   * useEffect 
   */
  useEffect(() => {
    document.title = 'Login | SiteStatusMonitoring.com';

    //auth stuff
    const itemStr = localStorage.getItem('authKey');
    if (itemStr) {
      console.log('local');
      const item = JSON.parse(itemStr);
      checkToken(item.value, false)
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      const getkey = urlParams.get('key');

      if (getkey) {
        console.log('key found');
        checkToken(getkey, true)
      } else {
        console.log('key not found');
        setAuthStatus(false);
      }
    }
  }, []);

  /**
   * 
   * @param {str}  key 
   * @param {bool} storeAndRedirect 
   */
  const checkToken = (key, storeAndRedirect) => {
    var myHeaders = new Headers();
    //myHeaders.append("vp-authorization", AUTH_TOKEN);

    var formdata = new FormData();
    formdata.append("key", key);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(API_URL + '/gettoken', requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.code === 200) {
          //need to store the user_id in localstorage for usage
          const settings = {
            menuOpen : menuOpen,
            //apiUrl: API_URL,
            userToken: data.token,
            //authToken: AUTH_TOKEN
          };
          setSettings(settings);
          
          if (storeAndRedirect) {
            const expirationDate = new Date();
            const item = {
              value: key,
              expires: expirationDate.getTime() + (1000 * 60 * 24 * 7), // Adding 7 days to the current date (makes no difference. Expires is handled at by the API)
            };
            localStorage.setItem('authKey', JSON.stringify(item));

            window.location.href = '/';
          } else {
            setAuthStatus(true);
          }
        } else {
          localStorage.removeItem('authKey'); //remove any key from local storage
          setAuthStatus(false);
        }
      });
  }

  /**
   * 
   */
  const menuToggle = () => {
    setMenuOpen(menuOpen => !menuOpen)
  }

  /**
   * 
   */
  const triggerRefreshSidebar = () => {
    setSidebarRelaod(sidebarReload => !sidebarReload)
  }

  return (
    <SettingsProvider value={settings}>
      <Router>
        {authStatus ? (
          <div className="w-full min-h-screen relative flex ct-docs-disable-sidebar-content overflow-x-hidden">
            <Sidebar reload={sidebarReload} menuToggle={menuToggle} menuOpen={menuOpen} />
            <div className={`relative bg-blueGray-100 dark:bg-primary w-full ${menuOpen ? 'lg:ml-64 xl:ml-72' : ''}`}>
              <Nav menuToggle={menuToggle} menuOpen={menuOpen} />

              <Routes>
                <Route path="/" exact element={<Home />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/settings/methods" element={<ContactMethods />} />
                <Route path="/settings/methods/:id" element={<ContactMethod />} />
                <Route path="/site/:id" element={<Site refreshSidebar={triggerRefreshSidebar} />} />
                <Route path="/add/" element={<Add refreshSidebar={triggerRefreshSidebar} />} />
                <Route path="/support/" element={<Support />} />
                <Route path="/site/edit/:id" element={<Add />} />

                {/* Fallback 404 Route */}
                <Route path="*" element={<NotFound />} />
              </Routes>
              
            </div>
          </div>
        ) : (
          <RedirectToLogin />
        )}
      </Router>
    </SettingsProvider>
  );
}

function RedirectToLogin() {
  return (
    <div className='min-h-screen flex-col flex items-center justify-center bg-gray-900 py-12 px-4 sm:px-6 lg:px-8'>
      <div className="max-w-md w-full">
        <img src="/images/logo-icon.svg" alt="Site Status logo" className='w-full md:w-36 mx-auto block mt-5' />
        <h1 className='text-center md:text-6xl text-2xl mb-0 mt-3 dark:text-white'>SiteStatus</h1>
        <h3 className='text-center text-base uppercase dark:text-white mb-16 mt-2'>Get Ahead of the downtime.</h3>
        <a href={LOGIN_URL} className="group relative w-full flex justify-center py-2 px-6 border border-transparent text-2xl font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50">
          Login
        </a>
      </div>
    </div>
  );
}

export default App;
